@import '~sanitize.css';
@import '~sanitize.css/forms.css';
@import '~sanitize.css/typography.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --ash: #e9ecef;
    --charcoal: #c4c6c8;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: var(--ash);
    border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--charcoal);
}

@font-face {
    font-family: 'nanum';
    src: url('assets/font/NanumMyeongjo.otf') format('opentype'),
    url('assets/font/NanumMyeongjoBold.otf') format('opentype'),
    url('assets/font/NanumMyeongjoExtraBold.otf') format('opentype');
}